<template>
  <v-dialog v-model="dialog" max-width="500px">
    <sign-in @success="closeDialog"></sign-in>
  </v-dialog>
</template>
<script>
import dialogMixin from "@/mixins/dialogMixin";
import SignIn from "./SignIn"

export default {
  created(){
    this.showDialog()
  },
  components: {
    SignIn
  },
  mixins: [dialogMixin],
};
</script>
